import { PageKey } from '@src/models/page';
import { SideBarPageKey } from '@src/models/sideBar';

// pathname을 통하여 SideBar 어느 페이지에 있는지 알아내기 위한 constant
export const pathnameToSideBarPageKey: Record<string, SideBarPageKey> = {
  '/dashboard': PageKey.DASHBOARD,
  '/giftcard': PageKey.GIFT_CARD_CONTENT_LIST,
  '/giftcard/lookup': PageKey.GIFT_CARD_LIST,
  '/mainpage/edit': PageKey.APP_MAIN_EDIT,
  '/notice/splashscreen': PageKey.SPLASH_SCREEN,
  '/notice/popups': PageKey.POP_UPS,
  '/notice/onboarding': PageKey.ON_BOARDING,
  '/notice/pushsend': PageKey.PUSH_SEND,
  '/notice/pushreservation': PageKey.PUSH_RESERVATION,
  '/notice/eventpage': PageKey.EVENT_PAGE,
  '/user': PageKey.MANAGE_USER,
  '/user/playLog': PageKey.PLAY_LOG,
  '/user/playLogs': PageKey.PLAY_LOGS,
  '/user/statistics': PageKey.USER_STATISTICS,
  '/user/quit': PageKey.QUIT_USERS,
  '/point/dashboard': PageKey.POINT_DASHBOARD,
  '/point/log': PageKey.POINT_LOG,
  '/point/promotion': PageKey.POINT_PROMOTION,
  '/point/provision': PageKey.POINT_PROVISION,
  '/advertisement/projects': PageKey.AD_PROJECT_LIST,
  '/advertisement/limit': PageKey.AD_LIMIT,
  '/system/settings': PageKey.SYSTEM,
  '/report/creator-feed': PageKey.REPORT_CREATOR_FEED,
  '/report/creator-community': PageKey.REPORT_CREATOR_COMMUNITY,
  '/report/user-community': PageKey.REPORT_USER_COMMUNITY,
  '/report/review-help': PageKey.REPORT_REVIEW_AND_HELP,
  '/etc/short-link': PageKey.ETC_SHORT_LINK,
  '/etc/file': PageKey.ETC_FILE,
  '/creator/channel': PageKey.CREATOR_CHANNEL,
  '/content/project': PageKey.CONTENT_PROJECT,
  '/content/preference': PageKey.CONTENT_PREFERENCE,
  '/main/groups': PageKey.MAIN_GROUP,
  '/main/page-setting': PageKey.MAIN_PAGE_SETTING,
  '/main/module-setting': PageKey.MAIN_MODULE_SETTING,
  '/sub-page': PageKey.SUB_PAGE_SETTING,
  '/dagoo/amulet': PageKey.DAGOO_AMULET,
  '/dagoo/achievements': PageKey.DAGOO_ACHIEVEMENTS,
  '/community/topic': PageKey.COMMUNITY_TOPIC,
  '/community/banner': PageKey.COMMUNITY_BANNER,
  '/black-story/quiz-templates': PageKey.QUIZ_TEMPLATES,
  '/black-story/tutorial-quizzes': PageKey.TUTORIAL_QUIZ,
  '/black-story/tutorial-users': PageKey.TUTORIAL_USER,
  '/black-story/rooms': PageKey.ROOM
};

export const sideBarPageKeyLabel: Record<SideBarPageKey, string> = {
  [PageKey.DASHBOARD]: '대시보드',
  [PageKey.GIFT_CARD_CONTENT_LIST]: '콘텐츠 관리',
  [PageKey.GIFT_CARD_LIST]: '기프트카드 조회',
  [PageKey.APP_MAIN_EDIT]: '홈페이지 메인 관리',
  [PageKey.SPLASH_SCREEN]: '스플래시 화면',
  [PageKey.POP_UPS]: '팝업 관리',
  [PageKey.ON_BOARDING]: '온보딩 관리',
  [PageKey.PUSH_SEND]: '푸시 보내기',
  [PageKey.PUSH_RESERVATION]: '푸시 예약 관리',
  [PageKey.EVENT_PAGE]: '이벤트 페이지 관리',
  [PageKey.MANAGE_USER]: '플레이어 목록',
  [PageKey.PLAY_LOG]: '플레이 내역',
  [PageKey.PLAY_LOGS]: '플레이 내역',
  [PageKey.USER_STATISTICS]: '플레이어 통계',
  [PageKey.QUIT_USERS]: '탈퇴유저 목록',
  [PageKey.POINT_DASHBOARD]: '츄로 대시보드',
  [PageKey.POINT_LOG]: '츄로 로그',
  [PageKey.POINT_PROMOTION]: '츄로 프로모션',
  [PageKey.POINT_PROVISION]: '츄로 지급',
  [PageKey.AD_PROJECT_LIST]: '게임 목록',
  [PageKey.AD_LIMIT]: '최대 시청 횟수 관리',
  [PageKey.SYSTEM]: '시스템 관리',
  [PageKey.REPORT_CREATOR_FEED]: '크리에이터 피드',
  [PageKey.REPORT_CREATOR_COMMUNITY]: '크리에이터 커뮤니티',
  [PageKey.REPORT_USER_COMMUNITY]: '유저 커뮤니티',
  [PageKey.REPORT_REVIEW_AND_HELP]: '후기글/도움글',
  [PageKey.ETC_SHORT_LINK]: '단축링크 관리',
  [PageKey.ETC_FILE]: '파일 관리',
  [PageKey.CREATOR_CHANNEL]: '채널 관리',
  [PageKey.CONTENT_PROJECT]: '프로젝트 관리',
  [PageKey.CONTENT_PREFERENCE]: '취향 관리',
  [PageKey.MAIN_GROUP]: '메인 그룹 관리 (N)',
  [PageKey.MAIN_SECTION]: '메인 섹션 관리 (N)',
  [PageKey.MAIN_HEADER_THUMBNAIL]: '메인 썸네일 관리 (N)',
  [PageKey.MAIN_PAGE_SETTING]: '메인 UI 관리',
  [PageKey.MAIN_MODULE_SETTING]: '도메인 관리',
  [PageKey.SUB_PAGE_SETTING]: '서브 페이지 관리',
  [PageKey.DAGOO_AMULET]: '부적 관리',
  [PageKey.DAGOO_ACHIEVEMENTS]: '업적 관리',
  [PageKey.COMMUNITY_TOPIC]: '토픽 관리',
  [PageKey.COMMUNITY_BANNER]: '배너 관리',
  [PageKey.QUIZ_TEMPLATES]: '퀴즈 템플릿 목록',
  [PageKey.TUTORIAL_QUIZ]: '튜토리얼 퀴즈 목록',
  [PageKey.TUTORIAL_USER]: '튜토리얼 유저 목록',
  [PageKey.ROOM]: '튜토리얼 룸 목록'
};
