import { Modal, Typography } from 'antd';
import { ModalProps } from 'antd/lib';
import Button from '@src/components/Button';

interface CorrectStoryModalProps {
  modal: ModalProps;
  correctStory: string;
  closeModal: () => void;
}

export function CorrectStoryModal({ modal, correctStory, closeModal }: CorrectStoryModalProps) {
  return (
    <Modal
      title="사건의 전말"
      onCancel={closeModal}
      open={modal.open}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          닫기
        </Button>
      ]}
    >
      <Typography.Paragraph>{correctStory}</Typography.Paragraph>
    </Modal>
  );
}
