import { PromotionRewardResponseModel } from '@uniquegood/realworld-admin-interface/dist';
import { Input, InputNumber, Modal, ModalProps, Select, Space } from 'antd';
import React, { BaseSyntheticEvent, useEffect } from 'react';

/**
 * schema
 * title: string
 * description: string
 * joinEnableCount: number
 * startedAt: Date
 * endedAt: Date
 * promotionType: Daily | Basic
 * manager: string
 * viewType: ?
 * promotionRewards: [
 *   {
 *     rewardPoint: number
 *     description: string
 *     isActivate: boolean
 *     message: string
 *   }
 * ]
 * isActivate: boolean
 */

interface Item {
  rewardPoint?: number;
  rewardDescription?: string;
  isActivate?: boolean;
  message?: string;
}

interface PromotionRewardModifyModalProps {
  closeModal: () => unknown;
  modal: ModalProps;
  list: (PromotionRewardResponseModel & { key: React.Key; id: string; isNew?: boolean })[];
  setList: React.Dispatch<
    React.SetStateAction<(PromotionRewardResponseModel & { key: React.Key; id: string; isNew?: boolean })[]>
  >;
  selectedReward?: (PromotionRewardResponseModel & { id: string }) | null;
}

export function PromotionRewardModifyModal({
  closeModal,
  modal,
  list,
  setList,
  selectedReward
}: PromotionRewardModifyModalProps) {
  const [input, setInput] = React.useState<Item>();

  const handlePointChange = (value: number | null) => {
    setInput({
      ...input,
      rewardPoint: value as number
    });
  };

  const handleIsActivateChange = (value: boolean) => {
    setInput({
      ...input,
      isActivate: value
    });
  };

  const handleDescriptionChange = (e: BaseSyntheticEvent) => {
    setInput({
      ...input,
      rewardDescription: e.target.value
    });
  };

  const handleMessageChange = (e: BaseSyntheticEvent) => {
    setInput({
      ...input,
      message: e.target.value
    });
  };

  const handleClick = () => {
    setInput(undefined);
    setList((prev) => {
      return prev.map((item) => {
        if (item.id === selectedReward?.id) {
          return {
            ...item,
            rewardPoint: input?.rewardPoint || 0,
            description: input?.rewardDescription || '',
            isActivate: input?.isActivate || false,
            message: input?.message || '',
            isModified: true
          };
        }
        return item;
      });
    });
    closeModal();
  };

  useEffect(() => {
    if (!selectedReward) return;

    setInput({
      rewardPoint: selectedReward.rewardPoint,
      rewardDescription: selectedReward.rewardDescription || '',
      isActivate: selectedReward.isActivate,
      message: selectedReward.message || ''
    });
  }, [selectedReward]);

  return (
    <Modal {...modal} onOk={handleClick} okText="저장" cancelText="닫기">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space>
          <InputNumber
            value={input?.rewardPoint}
            onChange={handlePointChange}
            id="rewardPoint"
            min={0}
            placeholder="츄로 수량"
            width="100%"
          />
          <Select
            value={input?.isActivate}
            onChange={handleIsActivateChange}
            id="isActivate"
            options={[
              {
                value: true,
                label: '활성화'
              },
              {
                value: false,
                label: '비활성화'
              }
            ]}
            placeholder="활성화 여부"
          />
        </Space>
        <Input.TextArea
          value={input?.rewardDescription}
          onChange={handleDescriptionChange}
          id="description"
          autoSize
          placeholder="보상 설명"
        />

        <Input value={input?.message} onChange={handleMessageChange} id="message" placeholder="보상 획득 메시지" />
      </Space>
    </Modal>
  );
}
