/* eslint-disable react/no-array-index-key */
import { Modal, ModalProps } from 'antd';
import styled from '@emotion/styled';
import { QuizMessageItem } from '@src/models/quiz';
import Button from '@src/components/Button';

interface RoomChatHistoryModalProps {
  modal: ModalProps;
  messages: QuizMessageItem[];
  closeModal: () => unknown;
}

export function RoomChatHistoryModal({ modal, messages, closeModal }: RoomChatHistoryModalProps) {
  return (
    <Modal
      title="채팅 내역"
      open={modal.open}
      onCancel={closeModal}
      okText=""
      footer={[
        <Button key="cancel" onClick={closeModal}>
          닫기
        </Button>
      ]}
    >
      <ChatContainer>
        {messages.map((message, idx) => (
          <>
            <ChatMessage key={idx + 1}>
              <UserName>{message.user.nickname}</UserName>
              <MessageContent>{message.message}</MessageContent>
              <TimeStamp>{new Date(message.timestamp).toLocaleTimeString()}</TimeStamp>
            </ChatMessage>
            {message.response && (
              <ChatMessage key={idx} isAnswer>
                <UserName>바거스 AI</UserName>
                <MessageContent>{message.response.answer}</MessageContent>
              </ChatMessage>
            )}
          </>
        ))}
      </ChatContainer>
    </Modal>
  );
}

const ChatContainer = styled.div`
  height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const ChatMessage = styled.div<{ isAnswer?: boolean }>`
  margin: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => (props.isAnswer ? '#e3f2fd' : '#f5f5f5')};
  max-width: 80%;
  align-self: ${(props) => (props.isAnswer ? 'flex-end' : 'flex-start')};
  word-break: break-word;
`;

const UserName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const MessageContent = styled.div`
  white-space: pre-wrap;
`;

const TimeStamp = styled.div`
  font-size: 12px;
  color: #666;
  text-align: right;
  margin-top: 4px;
`;
