import { PageHeader } from '@ant-design/pro-components';
import { Divider, Input, message, Pagination, Space, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useGetQuizTemplates } from '@src/apis/black-story/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { GetQuizTemplateParams, QuizTemplate } from '@src/models/quiz';
import Button from '@src/components/Button';
import ConfirmDeleteModal from '@src/components/Modal/ConfirmDeleteModal';
import { useDeleteQuizTemplate } from '@src/apis/black-story/mutations';
import { getQuizTemplateColumns } from './QuizTemplateColumns';
import { QuizTemplateExcelModal } from './QuizTemplateExcelModal';
import { QuizTemplateCreateModal } from './QuizTemplateCreateModal';
import { QuizTemplateUpdateModal } from './QuizTemplateUpdateModal';

const { Search } = Input;

export default function () {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const { openModal: openDetailModal, closeModal: closeDetailModal, modal: detailModal } = useModalState();
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openUpdateModal, closeModal: closeUpdateModal, modal: updateModal } = useModalState();
  const { openModal: openDeleteModal, closeModal: closeDeleteModal, modal: deleteModal } = useModalState();
  const { openModal: openExcelModal, closeModal: closeExcelModal, modal: excelModal } = useModalState();

  const [totalCount, setTotalCount] = React.useState<number | undefined>(0);
  const [quizTemplates, setQuizTemplates] = React.useState<QuizTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<QuizTemplate | null>(null);

  const [query, setQuery] = useState<GetQuizTemplateParams>({ limit: 10, page: 1 });
  const { data: quizTemplatesData, isLoading, refetch } = useGetQuizTemplates(query);
  const { mutateAsync } = useDeleteQuizTemplate();

  useEffect(() => {
    if (quizTemplatesData && !isLoading) {
      setTotalCount(quizTemplatesData.data.meta?.pagination?.totalCount);
      setQuizTemplates(quizTemplatesData.data.data ?? []);
    }
  }, [isLoading, quizTemplatesData]);
  const handleOpenUpdateModal = (template: QuizTemplate) => {
    setSelectedTemplate(template);
    openUpdateModal({
      title: '템플릿 수정',
      transitionName: ''
    });
  };

  const handleOpenDeleteModal = (id: number) => {
    openDeleteModal({
      title: '템플릿 삭제',
      onOk: async () => {
        await mutateAsync(id, {
          onSuccess: () => {
            message.success('템플릿이 삭제되었습니다.');
            refetch();
            closeDeleteModal();
          },
          onError: () => {
            message.error('템플릿 삭제 중 오류가 발생했습니다.');
            closeDeleteModal();
          }
        });
      }
    });
  };

  return (
    <DefaultLayout>
      <PageHeader
        title="바거스 퀴즈 템플릿 관리"
        subTitle="바거스 퀴즈 템플릿을 관리합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Space style={{ width: '100%', marginBottom: '16px' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              openCreateModal({
                title: '템플릿 생성',
                transitionName: ''
              });
            }}
          >
            템플릿 생성
          </Button>
          <Button
            icon={<UploadOutlined />}
            onClick={() => {
              openExcelModal({
                title: '엑셀 업로드',
                transitionName: ''
              });
            }}
          >
            엑셀로 생성하기
          </Button>
        </Space>
        <Table
          tableLayout="auto"
          rowKey={(record) => record.id}
          columns={getQuizTemplateColumns({
            openUpdateModal: handleOpenUpdateModal,
            openDeleteModal: handleOpenDeleteModal
          })}
          dataSource={quizTemplates}
          pagination={{
            position: ['bottomRight'],
            pageSize: query.limit,
            showSizeChanger: false,
            total: totalCount ?? 0,
            current: query.page ?? 1,
            onChange: (page) => setQuery({ ...query, page })
          }}
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        >
          <Pagination />
        </Table>
      </Content>
      <QuizTemplateCreateModal modal={createModal} closeCreateModal={closeCreateModal} refetchFn={refetch} />
      <QuizTemplateExcelModal modal={excelModal} closeExcelModal={closeExcelModal} refetchFn={refetch} />
      <QuizTemplateUpdateModal
        modal={updateModal}
        closeUpdateModal={closeUpdateModal}
        refetchFn={refetch}
        selectedTemplate={selectedTemplate}
      />
      <ConfirmDeleteModal modalData={deleteModal} />
    </DefaultLayout>
  );
}
