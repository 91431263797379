import { useQuery } from '@tanstack/react-query';
import { ProjectStatusFilter } from '@uniquegood/realworld-admin-interface';
import { mainPageApi, projectApi, subPageApi } from '.';

export const useGetSubPages = () => {
  return useQuery({
    queryKey: ['getSubPages'],
    queryFn: async () => {
      const { data } = await subPageApi.getMainPageGroups();

      return data;
    }
  });
};

export const useGetSubPageDetail = (id: string) => {
  return useQuery({
    queryKey: ['getSubPageDetail', id],
    queryFn: async () => {
      const { data } = await subPageApi.getSubPageDetail(id);

      return data;
    }
  });
};

export const useGetProjectDetail = (id: string) => {
  return useQuery({
    queryKey: ['getProjectDetail', id],
    queryFn: async () => {
      const { data } = await projectApi.getProjectsById(id);

      return data;
    }
  });
};

export const useGetMainPageProjects = () => {
  return useQuery({
    queryKey: ['getMainPageProjects'],
    queryFn: async () => {
      const { data } = await mainPageApi.getProjects();

      return data.projects;
    }
  });
};

export const useGetProjects = (props?: { keyword?: string; filter?: ProjectStatusFilter[] }) => {
  const { keyword, filter } = props || {};

  return useQuery({
    queryKey: ['getProjects', keyword, filter],
    queryFn: async () => {
      const { data } = await projectApi.getProjectsAll(keyword, filter);

      return data.data;
    }
  });
};
