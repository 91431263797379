import { Input, Modal, ModalProps, Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { ClueCategory, SubPageDetailResponseModel } from '@uniquegood/realworld-admin-interface';
import { usePatchSubPage } from '@src/apis/admin/mutations';
import { useGetProjects } from '@src/apis/admin/queries';

interface ModifyModalProps {
  modal: ModalProps;
  onClose: () => unknown;
  prevData: SubPageDetailResponseModel[];
  initialData: SubPageDetailResponseModel | undefined;
  category: ClueCategory | undefined;
}

export default function ModifyModal({ modal, onClose, initialData, prevData, category }: ModifyModalProps) {
  const { data: projects, isLoading } = useGetProjects({
    filter: ['Preparing', 'Released']
  });
  const { mutateAsync: patchSubPage } = usePatchSubPage();
  const [searchParams] = useSearchParams();
  const spaceId = searchParams.get('spaceId') || '';

  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [reservationLinkUrl, setReservationLinkUrl] = useState('');

  const handleSubmit = async () => {
    try {
      // 이미 목록에 존재하는 프로젝트를 추가하려는 경우
      if (prevData.map((item) => item.projectId).includes(selectedProjectId)) {
        toast.error('이미 추가된 프로젝트입니다.');
        return;
      }

      const shouldModifyItemIndex = prevData.findIndex((item) => item.projectId === initialData?.projectId);
      const before = prevData.slice(0, shouldModifyItemIndex);
      const after = prevData.slice(shouldModifyItemIndex + 1);
      const shouldModifyItem = { projectId: selectedProjectId, order: shouldModifyItemIndex + 1, reservationLinkUrl };
      const nextData = [...before, shouldModifyItem, ...after];

      await patchSubPage({
        id: spaceId,
        category,
        body: nextData
      });

      toast.success('프로젝트가 수정되었습니다.');
      onClose();
    } catch {
      toast.error('프로젝트 수정에 실패했습니다.');
    }
  };

  useEffect(() => {
    if (!initialData) {
      return;
    }

    setSelectedProjectId(initialData.projectId);
    setReservationLinkUrl(initialData.reservationLinkUrl || '');
  }, [initialData]);

  return (
    <Modal {...modal} onOk={handleSubmit} onCancel={onClose} title="프로젝트 수정" okText="확인" cancelText="닫기">
      <Select
        placeholder="추가할 프로젝트를 선택해주세요."
        value={selectedProjectId}
        showSearch
        options={projects?.map((project) => ({
          label: project.name,
          value: project.id
        }))}
        onChange={setSelectedProjectId}
        disabled={isLoading}
        filterOption={(input, option) => {
          if (!option) return false;

          return option.label?.includes(input) || false;
        }}
      />
      <Input
        value={reservationLinkUrl}
        onChange={(e) => setReservationLinkUrl(e.target.value)}
        placeholder="예약 링크를 입력해주세요."
        style={{ marginTop: '8px' }}
      />
    </Modal>
  );
}
