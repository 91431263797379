import { Form, Input, InputNumber, message, Modal, ModalProps, Select } from 'antd';
import { useEffect, useState } from 'react';
import useModalState from '@src/hooks/useModalState';
import { useUpdateQuizTemplate } from '@src/apis/black-story/mutations';
import { QuizTemplate, UpdateQuizTemplate } from '@src/models/quiz';

interface QuizTemplateUpdateModalProps {
  modal: ModalProps;
  refetchFn: () => unknown;
  closeUpdateModal: () => unknown;
  selectedTemplate: QuizTemplate | null;
}

export function QuizTemplateUpdateModal({
  modal,
  refetchFn,
  closeUpdateModal,
  selectedTemplate
}: QuizTemplateUpdateModalProps) {
  const [modalWidth, setModalWidth] = useState<number>(0);
  const [form] = Form.useForm();

  const { openModal: openCloseCheckModal, closeModal: closeCloseCheckModal, modal: closeCheckModal } = useModalState();

  const { mutateAsync } = useUpdateQuizTemplate();

  useEffect(() => {
    const updateWidth = () => {
      setModalWidth(window.innerWidth * 0.5);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      form.setFieldsValue({
        name: selectedTemplate.name,
        topic: selectedTemplate.topic,
        status: selectedTemplate.status,
        type: selectedTemplate.type,
        quantity: selectedTemplate.quantity,
        isEnable: selectedTemplate.isEnable
      });
    }
  }, [selectedTemplate, form]);

  const handleSave = async (value: UpdateQuizTemplate) => {
    if (!selectedTemplate) return;

    await mutateAsync(
      { id: selectedTemplate.id, param: value },
      {
        onSuccess: () => {
          message.success('템플릿 수정이 성공적으로 처리되었습니다.');
          refetchFn();
          closeUpdateModal();
        },
        onError: () => {
          message.error('템플릿 수정에 실패했습니다.');
        }
      }
    );
  };

  const handleCancel = () => {
    openCloseCheckModal({
      title: '정말 닫으시겠습니까?',
      width: modalWidth,
      onOk: () => {
        form.resetFields();
        closeUpdateModal();
        closeCloseCheckModal();
      }
    });
  };

  return (
    <>
      <Modal
        title="퀴즈 템플릿 수정"
        width={modalWidth}
        okText="저장"
        cancelText="닫기"
        maskClosable={false}
        onOk={form.submit}
        onCancel={handleCancel}
        open={modal.open}
        destroyOnClose
      >
        <Form form={form} onFinish={handleSave}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 8 }}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: '템플릿 명을 입력해주세요.' }]}
              style={{ width: '100%' }}
            >
              <Input placeholder="템플릿 명을 입력해주세요." style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="topic"
              rules={[{ required: true, message: '토픽을 입력해주세요.' }]}
              style={{ width: '100%' }}
            >
              <Input placeholder="토픽을 입력해주세요. [구분자는 ',' 입니다]" style={{ width: '100%' }} />
            </Form.Item>
          </div>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 8 }}>
            <Form.Item
              name="status"
              rules={[{ required: true, message: '템플릿 상태를 입력해주세요.' }]}
              style={{ width: '100%' }}
            >
              <Select
                placeholder="템플릿 상태"
                options={[
                  {
                    value: 'wait',
                    label: '대기'
                  }
                ]}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="type"
              rules={[{ required: true, message: '템플릿 상태를 입력해주세요.' }]}
              style={{ width: '100%' }}
            >
              <Select
                placeholder="템플릿 타입"
                options={[
                  {
                    value: 'Common',
                    label: '일반'
                  }
                ]}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item
              name="quantity"
              rules={[{ required: true, message: '생성 수량을 입력해주세요.', min: 1, type: 'number' }]}
              style={{ width: '100%' }}
            >
              <InputNumber min={1} placeholder="퀴즈 생성 수량" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="isEnable"
              rules={[{ required: true, message: '생성 수량을 입력해주세요.', type: 'boolean' }]}
              style={{ width: '100%' }}
            >
              <Select
                placeholder="활성화 상태"
                options={[
                  {
                    value: true,
                    label: '활성'
                  },
                  {
                    value: false,
                    label: '비활성'
                  }
                ]}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal {...closeCheckModal} okText="확인" cancelText="취소" />
    </>
  );
}
