import { PageHeader } from '@ant-design/pro-components';
import { Divider, Input, Pagination, Space, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useGetTutorialQuizzes } from '@src/apis/black-story/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { GetTutorialQuizParams, TutorialQuiz } from '@src/models/quiz';
import Button from '@src/components/Button';
import { TutorialQuizColumns } from './TutorialQuizColumns';
import { CorrectStoryModal } from './CorrectStoryModal';
import { BackgroundImageModal } from './BackgroundImageModal';

const { Search } = Input;

export default function () {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const [totalCount, setTotalCount] = React.useState<number | undefined>(0);
  const [tutorialQuizzes, setTutorialQuizzes] = React.useState<TutorialQuiz[]>([]);
  const [query, setQuery] = useState<GetTutorialQuizParams>({ limit: 10, page: 1 });
  const [correctStory, setCorrectStory] = useState<string>('');
  const [backgroundImage, setBackgroundImage] = useState<string>('');

  const { data: tutorialQuizzesData, isLoading } = useGetTutorialQuizzes(query);

  const { openModal: openDetailModal, closeModal: closeDetailModal, modal: detailModal } = useModalState();
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openModifyModal, closeModal: closeModifyModal, modal: modifyModal } = useModalState();
  const { openModal: openDeleteModal, closeModal: closeDeleteModal, modal: deleteModal } = useModalState();
  const {
    openModal: openCorrectStoryModal,
    closeModal: closeCorrectStoryModal,
    modal: correctStoryModal
  } = useModalState();
  const {
    openModal: openBackgroundImageModal,
    closeModal: closeBackgroundImageModal,
    modal: backgroundImageModal
  } = useModalState();

  useEffect(() => {
    if (tutorialQuizzesData && !isLoading) {
      setTotalCount(tutorialQuizzesData.data.meta?.pagination?.totalCount);
      setTutorialQuizzes(tutorialQuizzesData.data.data ?? []);
    }
  }, [isLoading, tutorialQuizzesData]);

  const handleCorrectStory = (correctStory: string) => {
    setCorrectStory(correctStory);
    openCorrectStoryModal({ title: '사건의 전말', transitionName: '' });
  };

  const handleBackgroundImage = (backgroundImage: string) => {
    setBackgroundImage(backgroundImage);
    openBackgroundImageModal({ title: '배경 이미지', transitionName: '' });
  };

  return (
    <DefaultLayout>
      <PageHeader title="퀴즈 관리" subTitle="튜토리얼 퀴즈를 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Space style={{ width: '100%', marginBottom: '16px' }}>
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              openCreateModal({
                title: '퀴즈 생성',
                transitionName: ''
              });
            }}
          >
            퀴즈 생성
          </Button>
        </Space>
        <Table
          tableLayout="auto"
          columns={TutorialQuizColumns({
            openBackgroundImageModal: handleBackgroundImage,
            openCorrectStoryModal: handleCorrectStory
          })}
          dataSource={tutorialQuizzes}
          pagination={{
            position: ['bottomRight'],
            pageSize: query.limit,
            showSizeChanger: false,
            total: totalCount ?? 0,
            current: query.page ?? 1,
            onChange: (page) => setQuery({ ...query, page })
          }}
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        >
          <Pagination />
        </Table>
      </Content>
      <CorrectStoryModal correctStory={correctStory} modal={correctStoryModal} closeModal={closeCorrectStoryModal} />
      <BackgroundImageModal
        backgroundImage={backgroundImage}
        modal={backgroundImageModal}
        closeModal={closeBackgroundImageModal}
      />
    </DefaultLayout>
  );
}
