import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Modal, ModalProps, Space, Table, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { Upload, UploadProps } from 'antd/lib';
import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { ColumnsType } from 'antd/es/table';
import Button from '@src/components/Button';
import { useCreateBulkQuizTemplate } from '@src/apis/black-story/mutations';
import { CreateQuizTemplate } from '@src/models/quiz';

interface ExcelData {
  name: string;
  topic: string;
  quantity: number;
}

interface QuizTemplateExcelModalProps {
  modal: ModalProps;
  refetchFn: () => unknown;
  closeExcelModal: () => unknown;
}

export function QuizTemplateExcelModal({ modal, refetchFn, closeExcelModal }: QuizTemplateExcelModalProps) {
  const [modalWidth, setModalWidth] = useState<number>(0);
  const [form] = Form.useForm();
  const [excelData, setExcelData] = useState<ExcelData[]>([]);
  const [columns, setColumns] = useState<ColumnsType<ExcelData>>([]);

  const { mutateAsync } = useCreateBulkQuizTemplate();

  useEffect(() => {
    const updateWidth = () => {
      setModalWidth(window.innerWidth * 0.5);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  const handleDownloadSample = () => {
    const sampleFileUrl = '/sample/quiz-template.xlsx'; // 실제 샘플 파일 경로로 수정 필요
    const link = document.createElement('a');
    link.href = sampleFileUrl;
    link.download = 'quiz-template-sample.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const beforeUpload = (file: RcFile) => {
    const isExcel =
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel';

    if (!isExcel) {
      message.error('엑셀 파일만 업로드 가능합니다!');
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleUpload: UploadProps['onChange'] = async (info) => {
    const file = info.file.originFileObj;
    if (!file) return;

    if (info.file.status !== 'done') return;

    try {
      const workbook = new ExcelJS.Workbook();
      const arrayBuffer = await file.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.worksheets[0];
      const jsonData: any[] = [];

      // 첫 번째 행은 헤더로 가정
      const headers: string[] = [];
      worksheet.getRow(1).eachCell((cell) => {
        headers.push(cell.value?.toString() || '');
      });

      // columns 데이터 생성
      const tableColumns: ColumnsType<ExcelData> = [
        // ExcelData 타입 명시
        { title: '이름', dataIndex: 'name', key: 'name' },
        { title: '주제', dataIndex: 'topic', key: 'topic' },
        { title: '수량', dataIndex: 'quantity', key: 'quantity' }
      ];
      setColumns(tableColumns);

      // 데이터 행 처리 (2번째 행부터)
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) return; // 헤더 행 스킵

        const rowData: ExcelData = {
          name: row.getCell(1).value?.toString() || '',
          topic: row.getCell(2).value?.toString() || '',
          quantity: Number(row.getCell(3).value) || 0
        };

        jsonData.push(rowData);
      });

      setExcelData(jsonData);

      message.success(`${info.file.name} 파일을 성공적으로 읽었습니다.`);
    } catch (error) {
      console.error('Excel 파일 읽기 오류:', error);
      message.error('파일 읽기에 실패했습니다.');
    }
  };

  const handleSave = async () => {
    if (excelData.length === 0) {
      message.warning('업로드된 데이터가 없습니다.');
      return;
    }

    const templates: CreateQuizTemplate[] = excelData.map((data) => {
      return {
        name: data.name,
        topic: data.topic,
        quantity: data.quantity,
        status: 'wait',
        type: 'Common',
        isEnable: true
      } as CreateQuizTemplate;
    });

    await mutateAsync(templates, {
      onSuccess: () => {
        message.success('템플릿 생성이 성공적으로 처리되었습니다.');
        refetchFn();
        closeExcelModal();
      },
      onError: () => {
        message.error('템플릿 생성에 실패했습니다.');
      }
    });
  };

  const handleCancel = () => {
    setExcelData([]); // 엑셀 데이터 초기화
    setColumns([]); // columns 초기화 추가
    form.resetFields();
    closeExcelModal();
  };

  return (
    <Modal
      title="엑셀 업로드"
      width={modalWidth}
      okText="업로드"
      onOk={handleSave}
      cancelText="닫기"
      onCancel={handleCancel}
      open={modal.open}
      destroyOnClose
    >
      <Form form={form}>
        <Form.Item>
          <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Upload
              accept=".xlsx,.xls"
              maxCount={1}
              beforeUpload={beforeUpload}
              onChange={handleUpload}
              customRequest={({ onSuccess }) => {
                if (onSuccess) {
                  onSuccess('ok');
                }
              }}
            >
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
            <Button icon={<DownloadOutlined />} onClick={handleDownloadSample} type="link">
              샘플 다운로드
            </Button>
          </Space>
        </Form.Item>
      </Form>
      {excelData.length > 0 && columns.length > 0 && (
        <Table dataSource={excelData} columns={columns} rowKey={(record) => record.name} />
      )}
    </Modal>
  );
}
