import { Badge, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { QuizTemplate } from '@src/models/quiz';
import Button from '@src/components/Button';

interface QuizTemplateColumnsProps {
  openUpdateModal: (template: QuizTemplate) => void;
  openDeleteModal: (id: number) => void;
}

const QUIZ_TEMPLATE_TYPE = [
  { key: 'Common', value: '일반', color: '' },
  { key: 'Tutorial', value: '튜토리얼', color: '' }
];

const QUIZ_TEMPLATE_STATUS = [
  { key: 'wait', value: '대기', color: '' },
  { key: 'generated', value: '생성완료', color: 'green-inverse' }
];

export const getQuizTemplateColumns = ({
  openUpdateModal,
  openDeleteModal
}: QuizTemplateColumnsProps): ColumnsType<QuizTemplate> => [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 80
  },
  {
    title: '템플릿 명',
    dataIndex: 'name',
    width: 200,
    key: 'name'
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (type) => {
      return <Tag>{QUIZ_TEMPLATE_TYPE.find((item) => item.key === type)?.value}</Tag>;
    }
  },
  {
    title: '주제',
    dataIndex: 'topic',
    key: 'topic',
    width: 200,
    render: (topic) => {
      const topicArray: string[] = topic.split(',');
      return (
        <>
          {topicArray.map((item: string, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag color="magenta" key={index}>
              {item}
            </Tag>
          ))}
        </>
      );
    }
  },
  {
    title: '생성 수량',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
    render: (quantity) => {
      return <Tag color="blue">{quantity}</Tag>;
    }
  },
  {
    title: '생성 상태',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status) => {
      const color = QUIZ_TEMPLATE_STATUS.find((item) => item.key === status)?.color;
      const value = QUIZ_TEMPLATE_STATUS.find((item) => item.key === status)?.value;
      return <Tag color={color}>{value}</Tag>;
    }
  },
  {
    title: '활성화 여부',
    dataIndex: 'isEnable',
    key: 'isEnable',
    width: 100,
    render: (value) => {
      return <div>{value ? <Badge dot color="green" text="활성화" /> : <Badge dot color="red" text="비활성화" />}</div>;
    }
  },
  {
    title: '액션',
    dataIndex: 'status',
    key: 'status',
    render: (status, record) => {
      return (
        <Space>
          <Button disabled={status === 'generated'} onClick={() => openUpdateModal(record)}>
            수정
          </Button>
          <Button type="default" danger disabled={status === 'generated'} onClick={() => openDeleteModal(record.id)}>
            삭제
          </Button>
        </Space>
      );
    }
  }
];
