import { useMutation } from '@tanstack/react-query';
import { CreateQuizTemplate, UpdateQuizTemplate } from '@src/models/quiz';
import { createBulkQuizTemplate, createQuizTemplate, deleteQuizTemplate, updateQuizTemplate } from '.';

export const useCreateQuizTemplate = () => {
  const { mutateAsync, isSuccess, isError, error } = useMutation({
    mutationFn: (param: CreateQuizTemplate) => createQuizTemplate(param),
    onSuccess: (data) => {
      console.log('퀴즈 템플릿이 생성되었습니다:', data);
    },
    onError: (error) => {
      // 에러 발생 시 처리할 로직
      console.error('퀴즈 템플릿 생성 중 오류 발생:', error);
    }
  });

  return { mutateAsync, isSuccess, isError, error };
};

export const useCreateBulkQuizTemplate = () => {
  const { mutateAsync, isSuccess, isError, error } = useMutation({
    mutationFn: (params: CreateQuizTemplate[]) => createBulkQuizTemplate(params),
    onSuccess: (data) => {
      console.log('퀴즈 템플릿이 생성되었습니다:', data);
    },
    onError: (error) => {
      // 에러 발생 시 처리할 로직
      console.error('퀴즈 템플릿 생성 중 오류 발생:', error);
    }
  });

  return { mutateAsync, isSuccess, isError, error };
};

export const useUpdateQuizTemplate = () => {
  const { mutateAsync, isSuccess, isError, error } = useMutation({
    mutationFn: ({ id, param }: { id: number; param: UpdateQuizTemplate }) => updateQuizTemplate(id, param),
    onSuccess: (data) => {
      console.log('퀴즈 템플릿이 수정되었습니다:', data);
    },
    onError: (error) => {
      // 에러 발생 시 처리할 로직
      console.error('퀴즈 템플릿 수정 중 오류 발생:', error);
    }
  });

  return { mutateAsync, isSuccess, isError, error };
};

export const useDeleteQuizTemplate = () => {
  const { mutateAsync, isSuccess, isError, error } = useMutation({
    mutationFn: (id: number) => deleteQuizTemplate(id),
    onSuccess: (data) => {
      console.log('퀴즈 템플릿이 삭제되었습니다:', data);
    },
    onError: (error) => {
      // 에러 발생 시 처리할 로직
      console.error('퀴즈 템플릿 삭제 중 오류 발생:', error);
    }
  });

  return { mutateAsync, isSuccess, isError, error };
};
