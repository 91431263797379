import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { usePostRefreshMutation } from '@src/apis/auth/mutations';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@src/utils/localStorage';
import { RWPayload } from '@src/types';

export function useAuthCheck() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mutateAsync: refresh } = usePostRefreshMutation();
  const accessToken = getLocalStorage('accessToken');
  const refreshToken = getLocalStorage('refreshToken');

  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const check = React.useCallback(async () => {
    // TODO: 인증 체크
    setIsLoading(true);

    try {
      // accessToken이 있을 때
      if (accessToken) {
        const decoded = jwtDecode<RWPayload>(accessToken);
        const exp = (decoded.exp || 0) * 1000;
        const { rwRole } = decoded;

        // 유효한 토큰인지 체크
        // 유효하지 않다면
        if (exp < Date.now()) {
          // refreshToken이 있을 때
          if (refreshToken) {
            // refreshToken으로 accessToken 재발급
            const data = await refresh({
              accessToken,
              refreshToken
            });

            const { rwUserName, rwUserProfile, rwEmail } = jwtDecode<RWPayload>(data.token);

            setLocalStorage('accessToken', data.token);
            setLocalStorage('refreshToken', data.refreshToken);
            setLocalStorage('userName', rwUserName);
            setLocalStorage('userImg', rwUserProfile || '');
            setLocalStorage('userEmail', rwEmail);
          } else {
            // refreshToken이 없을 때
            throw new Error('No Refresh Token');
          }
        } else if (rwRole !== 'RealWorld.Roles.Admin') {
          throw new Error('No Admin Role');
        }
      } else {
        // accessToken이 없을 때
        throw new Error('No Access Token');
      }
      setIsLoggedIn(true);
    } catch (e) {
      // TODO: 에러 처리
      // refresh 실패했을 때
      // accessToken, refreshToken 없을 때
      // 관리자 권한이 없을 때
      if (axios.isAxiosError(e)) {
        // axios 에러일 때
      } else if (e instanceof Error) {
        switch (e.message) {
          case 'No Admin Role':
            toast('관리자 권한이 없습니다.');
            break;
          default:
            if (location.pathname !== '/') {
              toast('로그인이 필요합니다.');
            }
        }
      }

      removeLocalStorage('accessToken');
      removeLocalStorage('refreshToken');
      removeLocalStorage('userName');
      removeLocalStorage('userImg');
      removeLocalStorage('userEmail');

      setIsLoggedIn(false);

      navigate('/', { replace: true });
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, location.pathname, navigate, refresh, refreshToken]);

  React.useEffect(() => {
    check();
  }, [check]);

  return {
    isLoading,
    isLoggedIn
  };
}
