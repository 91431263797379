import { useQuery } from '@tanstack/react-query';
import { GetQuizTemplateParams, GetRoomParams, GetTutorialQuizParams, GetTutorialUserParams } from '@src/models/quiz';
import { getQuizTemplates, getRooms, getTutorialQuizzes, getTutorialUsers } from '.';

export const useGetQuizTemplates = (query: GetQuizTemplateParams) => {
  return useQuery({ queryKey: ['getQuizTemplates', query], queryFn: () => getQuizTemplates(query) });
};

export const useGetTutorialQuizzes = (query: GetTutorialQuizParams) => {
  return useQuery({ queryKey: ['getTutorialQuizzes', query], queryFn: () => getTutorialQuizzes(query) });
};

export const useGetTutorialUsers = (query: GetTutorialUserParams) => {
  return useQuery({ queryKey: ['getTutorialUsers', query], queryFn: () => getTutorialUsers(query) });
};

export const useGetRooms = (query: GetRoomParams) => {
  return useQuery({ queryKey: ['getRooms', query], queryFn: () => getRooms(query) });
};
