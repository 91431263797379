import axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@src/utils/localStorage';
import { RWPayload } from '@src/types';
import { postRefreshToken } from './auth';

export const getDefaultAxiosInstance = () => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(async (config) => {
    let accessToken = getLocalStorage('accessToken');
    let refreshToken = getLocalStorage('refreshToken');

    const modifiedConfig = config;

    if (!modifiedConfig.headers) {
      return config;
    }

    try {
      // accessToken이 있을 때
      if (accessToken) {
        const decoded = jwtDecode<RWPayload>(accessToken);
        const exp = (decoded.exp || 0) * 1000;
        const { rwRole } = decoded;

        // 유효한 토큰인지 체크
        // 유효하지 않다면
        if (exp < Date.now()) {
          // refreshToken이 있을 때
          if (refreshToken) {
            // refreshToken으로 accessToken 재발급
            const { data } = await postRefreshToken({
              accessToken,
              refreshToken
            });

            const { rwUserName, rwUserProfile, rwEmail } = jwtDecode<RWPayload>(data.token);

            setLocalStorage('accessToken', data.token);
            setLocalStorage('refreshToken', data.refreshToken);
            setLocalStorage('userName', rwUserName);
            setLocalStorage('userImg', rwUserProfile || '');
            setLocalStorage('userEmail', rwEmail);

            accessToken = data.token;
            refreshToken = data.refreshToken;
          } else {
            // refreshToken이 없을 때
            throw new Error('No Refresh Token');
          }
        } else if (rwRole !== 'RealWorld.Roles.Admin') {
          throw new Error('No Admin Role');
        }
      } else {
        // accessToken이 없을 때
        throw new Error('No Access Token');
      }
    } catch (e) {
      removeLocalStorage('accessToken');
      removeLocalStorage('refreshToken');
      removeLocalStorage('userName');
      removeLocalStorage('userImg');
      removeLocalStorage('userEmail');

      window.location.href = '/';
      return config;
    }

    if (accessToken) {
      modifiedConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (value) => value,
    (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        window.location.href = '/logout';
        return undefined;
      }
      throw new AxiosError(error.message, error.code, error.config, error.request, error.response);
    }
  );

  return axiosInstance;
};
