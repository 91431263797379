import './global.css';

import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { PageKey } from './models/page';
import { pageKeyToPathnameFn } from './constants/page';
import AppMainEditPage from './pages/Notice/AppMainEdit';
import GiftCardCreatePage from './pages/GiftCard/Create';
import GiftCardListPage from './pages/GiftCard/List';
import GiftCardContentListPage from './pages/GiftCard/ContentList';
import LoginPage from './pages/Login';
import PushReservation from './pages/Notice/PushReservation';
import EventPage from './pages/Notice/EventPage';
import SplashScreenPage from './pages/Notice/SplashScreen';
import OnboardingPage from './pages/Notice/Onboarding';
import PushSendPage from './pages/Notice/PushSend';
import ManageUser from './pages/User/Manage';
import PointLog from './pages/Point/Log';
import PointPromotion from './pages/Point/Promotion';
import PointProvision from './pages/Point/Provision';
import PopUps from './pages/Notice/PopUp';
import AdvertisementProjectList from './pages/Advertisement/ProjectList';
import AdvertisementLimit from './pages/Advertisement/Limit';
import SystemSettings from './pages/System/Settings';
import Dashboard from './pages/Dashboard';
import PointDashboard from './pages/Point/Dashboard';
import PlayLog from './pages/User/PlayLog';
import UserStatistics from './pages/User/UserStatistics';
import QuitUser from './pages/User/QuitUser';
import Owned from './pages/User/Owned';
import Usage from './pages/User/Usage';
import CreatorCommunity from './pages/Report/CreatorCommunity';
import CreatorFeed from './pages/Report/CreatorFeed';
import UserCommunity from './pages/Report/UserCommunity';
import ReportReviewAndHelp from './pages/Report/ReviewAndHelp';
import ShortLink from './pages/Etc/ShortLink';
import Channel from './pages/Creator/Channel';
import Project from './pages/Content/Project';
import File from './pages/Content/File';
import ChannelDetail from './pages/Creator/ChannelDetail';
import Participant from './pages/Content/Project/Participant';
import Scenario from './pages/Content/Project/Scenario';
import Mission from './pages/Content/Project/Mission';
import Amulet from './pages/Dagoo/Amulet';
import Preference from './pages/Content/Preference';
import Achievements from './pages/Dagoo/Achievements';
import Topic from './pages/Community/Topic';
import Banner from './pages/Community/Banner';
import ThumbnailPage from './pages/Main/Thumbnail';
import GroupPage from './pages/Main/Group';
import SectionPage from './pages/Main/Section';
import ModifyPage from './pages/Main/Section/ModifyPage';
import QuizTemplate from './pages/BlackStory/QuizTemplate';
import ProtectedRouter from './components/ProtectedRouter';
import LoginRouter from './components/LoginRouter';
import TutorialQuiz from './pages/BlackStory/TutorialQuiz';
import TutorialUser from './pages/BlackStory/TutorialUser';
import Room from './pages/BlackStory/Room';
import SubPageSetting from './pages/Subpage/Subpage';

function App() {
  return (
    <>
      <Routes>
        <Route element={<ProtectedRouter />}>
          <Route path={pageKeyToPathnameFn[PageKey.DASHBOARD]()} element={<Dashboard />} />
          <Route path={pageKeyToPathnameFn[PageKey.APP_MAIN_EDIT]()} element={<AppMainEditPage />} />
          <Route
            path={pageKeyToPathnameFn[PageKey.GIFT_CARD_CREATE]({ keys: [':id'] })}
            element={<GiftCardCreatePage />}
          />
          <Route path={pageKeyToPathnameFn[PageKey.GIFT_CARD_LIST]()} element={<GiftCardListPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.GIFT_CARD_CONTENT_LIST]()} element={<GiftCardContentListPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.SPLASH_SCREEN]()} element={<SplashScreenPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.POP_UPS]()} element={<PopUps />} />
          <Route path={pageKeyToPathnameFn[PageKey.ON_BOARDING]()} element={<OnboardingPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.PUSH_SEND]()} element={<PushSendPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.PUSH_RESERVATION]()} element={<PushReservation />} />
          <Route path={pageKeyToPathnameFn[PageKey.EVENT_PAGE]()} element={<EventPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.MANAGE_USER]()} element={<ManageUser />} />
          <Route path={pageKeyToPathnameFn[PageKey.PLAY_LOG]({ keys: [':id'] })} element={<PlayLog />} />
          <Route path={pageKeyToPathnameFn[PageKey.PLAY_LOGS]()} element={<PlayLog />} />
          <Route path={pageKeyToPathnameFn[PageKey.USER_STATISTICS]()} element={<UserStatistics />} />
          <Route path={pageKeyToPathnameFn[PageKey.QUIT_USERS]()} element={<QuitUser />} />
          <Route path={pageKeyToPathnameFn[PageKey.OWNED_GAME]({ keys: [':id'] })} element={<Owned />} />
          <Route path={pageKeyToPathnameFn[PageKey.USAGE_LIST]({ keys: [':id'] })} element={<Usage />} />
          <Route path={pageKeyToPathnameFn[PageKey.AD_PROJECT_LIST]()} element={<AdvertisementProjectList />} />
          <Route path={pageKeyToPathnameFn[PageKey.AD_LIMIT]()} element={<AdvertisementLimit />} />
          <Route path={pageKeyToPathnameFn[PageKey.POINT_DASHBOARD]()} element={<PointDashboard />} />
          <Route path={pageKeyToPathnameFn[PageKey.POINT_LOG]()} element={<PointLog />} />
          <Route path={pageKeyToPathnameFn[PageKey.POINT_PROMOTION]()} element={<PointPromotion />} />
          <Route path={pageKeyToPathnameFn[PageKey.POINT_PROVISION]()} element={<PointProvision />} />
          <Route path={pageKeyToPathnameFn[PageKey.SYSTEM]()} element={<SystemSettings />} />
          <Route path={pageKeyToPathnameFn[PageKey.REPORT_CREATOR_FEED]()} element={<CreatorFeed />} />
          <Route path={pageKeyToPathnameFn[PageKey.REPORT_CREATOR_COMMUNITY]()} element={<CreatorCommunity />} />
          <Route path={pageKeyToPathnameFn[PageKey.REPORT_USER_COMMUNITY]()} element={<UserCommunity />} />
          <Route path={pageKeyToPathnameFn[PageKey.REPORT_REVIEW_AND_HELP]()} element={<ReportReviewAndHelp />} />
          <Route path={pageKeyToPathnameFn[PageKey.ETC_SHORT_LINK]()} element={<ShortLink />} />
          <Route path={pageKeyToPathnameFn[PageKey.ETC_FILE]()} element={<File />} />
          <Route path={pageKeyToPathnameFn[PageKey.CREATOR_CHANNEL]()} element={<Channel />} />
          <Route path={pageKeyToPathnameFn[PageKey.CONTENT_PROJECT]()} element={<Project />} />
          <Route
            path={pageKeyToPathnameFn[PageKey.CONTENT_CHANNEL_DETAIL]({ keys: [':id'] })}
            element={<ChannelDetail />}
          />
          <Route path={pageKeyToPathnameFn[PageKey.CREATOR_CHANNEL]()} element={<Channel />} />
          <Route path={pageKeyToPathnameFn[PageKey.CREATOR_CHANNEL_PROJECT]({ keys: [':id'] })} element={<Project />} />
          <Route path={pageKeyToPathnameFn[PageKey.CONTENT_PROJECT]()} element={<Project />} />
          <Route
            path={pageKeyToPathnameFn[PageKey.CONTENT_PROJECT_PARTICIPANT]({ keys: [':id'] })}
            element={<Participant />}
          />
          <Route
            path={pageKeyToPathnameFn[PageKey.CONTENT_PROJECT_SCENARIO]({ keys: [':id'] })}
            element={<Scenario />}
          />
          <Route
            path={pageKeyToPathnameFn[PageKey.CONTENT_PROJECT_MISSION]({ keys: [':projectId', ':scenarioId'] })}
            element={<Mission />}
          />
          <Route
            path={pageKeyToPathnameFn[PageKey.CONTENT_CHANNEL_DETAIL]({ keys: [':id'] })}
            element={<ChannelDetail />}
          />
          <Route path={pageKeyToPathnameFn[PageKey.CONTENT_PREFERENCE]()} element={<Preference />} />
          <Route path={pageKeyToPathnameFn[PageKey.MAIN_GROUP]()} element={<GroupPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.MAIN_SECTION]({ keys: [':groupId'] })} element={<SectionPage />} />
          <Route
            path={pageKeyToPathnameFn[PageKey.MAIN_SECTION_DETAIL]({ keys: [':groupId', ':sectionId'] })}
            element={<ModifyPage />}
          />
          <Route
            path={pageKeyToPathnameFn[PageKey.MAIN_HEADER_THUMBNAIL]({ keys: [':groupId'] })}
            element={<ThumbnailPage />}
          />
          <Route path={pageKeyToPathnameFn[PageKey.SUB_PAGE_SETTING]()} element={<SubPageSetting />} />
          <Route path={pageKeyToPathnameFn[PageKey.DAGOO_AMULET]()} element={<Amulet />} />
          <Route path={pageKeyToPathnameFn[PageKey.DAGOO_ACHIEVEMENTS]()} element={<Achievements />} />
          <Route path={pageKeyToPathnameFn[PageKey.COMMUNITY_TOPIC]()} element={<Topic />} />
          <Route path={pageKeyToPathnameFn[PageKey.COMMUNITY_BANNER]()} element={<Banner />} />
          <Route path={pageKeyToPathnameFn[PageKey.QUIZ_TEMPLATES]()} element={<QuizTemplate />} />
          <Route path={pageKeyToPathnameFn[PageKey.TUTORIAL_QUIZ]()} element={<TutorialQuiz />} />
          <Route path={pageKeyToPathnameFn[PageKey.TUTORIAL_USER]()} element={<TutorialUser />} />
          <Route path={pageKeyToPathnameFn[PageKey.ROOM]()} element={<Room />} />
        </Route>
        <Route element={<LoginRouter />}>
          <Route path={pageKeyToPathnameFn[PageKey.LOGIN]()} element={<LoginPage />} />
          <Route path={pageKeyToPathnameFn[PageKey.LOGOUT]()} element={<LoginPage />} />
        </Route>
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
