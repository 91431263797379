import { PageHeader } from '@ant-design/pro-components';
import { Divider, Pagination, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetRooms } from '@src/apis/black-story/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { GetRoomParams, QuizMessageItem, Room } from '@src/models/quiz';
import { RoomColumns } from './RoomColumns';
import { RoomChatHistoryModal } from './RoomChatHistoryModal';

export default function () {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const [totalCount, setTotalCount] = React.useState<number | undefined>(0);
  const [query, setQuery] = useState<GetRoomParams>({ limit: 10, page: 1 });
  const [rooms, setRooms] = React.useState<Room[]>([]);
  const [chatHistoryData, setChatHistoryData] = React.useState<QuizMessageItem[]>([]);

  const { data: roomsData, isLoading } = useGetRooms(query);
  const { openModal: openChatModal, closeModal: closeChatModal, modal: chatModal } = useModalState();

  useEffect(() => {
    if (roomsData && !isLoading) {
      setTotalCount(roomsData.data.meta?.pagination?.totalCount);
      setRooms(roomsData.data.data ?? []);
    }
  }, [isLoading, roomsData]);

  const handleOpenChatHistory = (messages: QuizMessageItem[]) => {
    setChatHistoryData(messages);
    openChatModal({ title: '채팅 내역', transitionName: '' });
  };

  return (
    <DefaultLayout>
      <PageHeader title="룸 관리" subTitle="튜토리얼 룸을 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Table
          tableLayout="auto"
          columns={RoomColumns({ openChatModal: handleOpenChatHistory })}
          dataSource={rooms}
          rowKey={(record) => record.id}
          pagination={{
            position: ['bottomRight'],
            pageSize: query.limit,
            showSizeChanger: false,
            total: totalCount ?? 0,
            current: query.page ?? 1,
            onChange: (page) => setQuery({ ...query, page })
          }}
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        >
          <Pagination />
        </Table>
      </Content>
      <RoomChatHistoryModal modal={chatModal} messages={chatHistoryData} closeModal={closeChatModal} />
    </DefaultLayout>
  );
}
