import { Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { MessageOutlined } from '@ant-design/icons';
import { QuizMessageItem, Room } from '@src/models/quiz';
import Button from '@src/components/Button';

interface RoomColumnsProps {
  openChatModal: (messages: QuizMessageItem[]) => void;
  onEdit?: (room: Room) => void;
  onDelete?: (room: Room) => void;
}

const ROOM_STATUS = [
  { key: 'playing', value: '진행중', color: 'pink' },
  { key: 'failed', value: '실패', color: 'red' },
  { key: 'ended', value: '성공', color: 'green' }
] as const;

export const RoomColumns = ({ openChatModal }: RoomColumnsProps): ColumnsType<Room> => [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 80
  },
  {
    title: '방 상태',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      const color = ROOM_STATUS.find((item) => item.key === status)?.color;
      const value = ROOM_STATUS.find((item) => item.key === status)?.value;
      return <Tag color={color}>{value}</Tag>;
    }
  },
  {
    title: '정답 여부',
    dataIndex: 'isCorrect',
    key: 'isCorrect',
    render: (isCorrect, room) => {
      if (room.status === 'ended' && isCorrect) {
        return <Tag color="green">정답</Tag>;
      }

      if (room.status === 'failed' && !isCorrect) {
        return <Tag color="red">실패</Tag>;
      }

      return <Tag>대기</Tag>;
    }
  },
  {
    title: '총 참여 인원',
    dataIndex: 'totalUserCount',
    key: 'totalUserCount'
  },
  {
    title: '종료 시점 인원',
    dataIndex: 'endedUserCount',
    key: 'endedUserCount'
  },
  {
    title: '총 질문 수',
    dataIndex: 'questionCount',
    key: 'questionCount'
  },
  {
    title: '채팅 내역',
    dataIndex: 'messages',
    key: 'messages',
    render: (messages) => {
      const parsedMessages = JSON.parse(messages) as QuizMessageItem[];
      return (
        <Button disabled={!messages} icon={<MessageOutlined />} onClick={() => openChatModal(parsedMessages)}>
          보기
        </Button>
      );
    }
  },
  {
    title: '액션',
    dataIndex: 'action',
    key: 'action',
    render: () => {
      return (
        <Space>
          <Button>수정</Button>
          <Button>삭제</Button>
        </Space>
      );
    }
  }
];
