import qs from 'qs';
import { PageKey } from '@src/models/page';

export const DEFAULT_AFTER_LOGIN_PAGE = PageKey.DASHBOARD;

function getPathnameWithQueryString(pathname: string, querystring?: any) {
  if (querystring) {
    return `${pathname}?${qs.stringify(querystring)}`;
  }
  return pathname;
}

interface PathnameFnProps {
  keys?: string[];
  querystring?: any;
}

// PageKey 모델을 통해 어느 pathname으로 움직여야 하는지에 대한 constant
export const pageKeyToPathnameFn: Record<PageKey, (props?: PathnameFnProps) => string> = {
  [PageKey.DASHBOARD]: (props) => getPathnameWithQueryString('/dashboard', props?.querystring),
  [PageKey.GIFT_CARD_CONTENT_LIST]: (props) => getPathnameWithQueryString('/giftcard', props?.querystring),
  [PageKey.GIFT_CARD_LIST]: (props) => getPathnameWithQueryString('/giftcard/lookup', props?.querystring),
  [PageKey.GIFT_CARD_CREATE]: (props) =>
    getPathnameWithQueryString(`/giftcard/create/${props?.keys?.[0]}`, props?.querystring),
  [PageKey.LOGIN]: (props) => getPathnameWithQueryString('/', props?.querystring),
  [PageKey.LOGOUT]: (props) => getPathnameWithQueryString('/logout', props?.querystring),
  [PageKey.APP_MAIN_EDIT]: (props) => getPathnameWithQueryString('/mainpage/edit', props?.querystring),
  [PageKey.SPLASH_SCREEN]: (props) => getPathnameWithQueryString('/notice/splashscreen', props?.querystring),
  [PageKey.POP_UPS]: (props) => getPathnameWithQueryString('/notice/popups', props?.querystring),
  [PageKey.ON_BOARDING]: (props) => getPathnameWithQueryString('/notice/onboarding', props?.querystring),
  [PageKey.PUSH_SEND]: (props) => getPathnameWithQueryString('/notice/pushsend', props?.querystring),
  [PageKey.PUSH_RESERVATION]: (props) => getPathnameWithQueryString('/notice/pushreservation', props?.querystring),
  [PageKey.EVENT_PAGE]: (props) => getPathnameWithQueryString('/notice/eventpage', props?.querystring),
  [PageKey.MANAGE_USER]: (props) => getPathnameWithQueryString('/user', props?.querystring),
  [PageKey.PLAY_LOGS]: (props) => getPathnameWithQueryString(`/user/playLogs`, props?.querystring),
  [PageKey.PLAY_LOG]: (props) =>
    getPathnameWithQueryString(`/user/playLog/${props?.keys?.[0] || ''}`, props?.querystring),
  [PageKey.USER_STATISTICS]: (props) => getPathnameWithQueryString('/user/statistics', props?.querystring),
  [PageKey.QUIT_USERS]: (props) => getPathnameWithQueryString('/user/quit', props?.querystring),
  [PageKey.OWNED_GAME]: (props) => getPathnameWithQueryString(`/user/owned/${props?.keys?.[0]}`, props?.querystring),
  [PageKey.USAGE_LIST]: (props) => getPathnameWithQueryString(`/user/usage/${props?.keys?.[0]}`, props?.querystring),
  [PageKey.AD_PROJECT_LIST]: (props) => getPathnameWithQueryString('/advertisement/projects', props?.querystring),
  [PageKey.AD_LIMIT]: (props) => getPathnameWithQueryString('/advertisement/limit', props?.querystring),
  [PageKey.POINT_DASHBOARD]: (props) => getPathnameWithQueryString('/point/dashboard', props?.querystring),
  [PageKey.POINT_LOG]: (props) => getPathnameWithQueryString('/point/log', props?.querystring),
  [PageKey.POINT_PROMOTION]: (props) => getPathnameWithQueryString('/point/promotion', props?.querystring),
  [PageKey.POINT_PROVISION]: (props) => getPathnameWithQueryString('/point/provision', props?.querystring),
  [PageKey.SYSTEM]: (props) => getPathnameWithQueryString('/system/settings', props?.querystring),
  [PageKey.REPORT_CREATOR_FEED]: (props) => getPathnameWithQueryString('/report/creator-feed', props?.querystring),
  [PageKey.REPORT_CREATOR_COMMUNITY]: (props) =>
    getPathnameWithQueryString('/report/creator-community', props?.querystring),
  [PageKey.REPORT_USER_COMMUNITY]: (props) => getPathnameWithQueryString('/report/user-community', props?.querystring),
  [PageKey.REPORT_REVIEW_AND_HELP]: (props) => getPathnameWithQueryString('/report/review-help', props?.querystring),
  [PageKey.ETC_SHORT_LINK]: (props) => getPathnameWithQueryString('/etc/short-link', props?.querystring),
  [PageKey.ETC_FILE]: (props) => getPathnameWithQueryString('/etc/file', props?.querystring),
  [PageKey.CREATOR_CHANNEL]: (props) => getPathnameWithQueryString('/creator/channel', props?.querystring),
  [PageKey.CREATOR_CHANNEL_PROJECT]: (props) =>
    getPathnameWithQueryString(`/creator/channel/${props?.keys?.[0]}/project`, props?.querystring),
  [PageKey.CONTENT_CHANNEL_DETAIL]: (props) =>
    getPathnameWithQueryString(`/creator/channel/${props?.keys?.[0]}`, props?.querystring),
  [PageKey.CONTENT_PROJECT]: (props) => getPathnameWithQueryString('/content/project', props?.querystring),
  [PageKey.CONTENT_PROJECT_PARTICIPANT]: (props) =>
    getPathnameWithQueryString(`/content/project/${props?.keys?.[0]}/participant`),
  [PageKey.CONTENT_PROJECT_SCENARIO]: (props) =>
    getPathnameWithQueryString(`/content/project/${props?.keys?.[0]}/scenario`),
  [PageKey.CONTENT_PROJECT_MISSION]: (props) =>
    getPathnameWithQueryString(`/content/project/${props?.keys?.[0]}/scenario/${props?.keys?.[1]}/mission`),
  [PageKey.CONTENT_PREFERENCE]: (props) => getPathnameWithQueryString('/content/preference', props?.querystring),
  [PageKey.MAIN_GROUP]: (props) => getPathnameWithQueryString('/main/groups', props?.querystring),
  [PageKey.MAIN_SECTION]: (props) =>
    getPathnameWithQueryString(`/main/groups/${props?.keys?.[0]}/sections`, props?.querystring),
  [PageKey.MAIN_SECTION_DETAIL]: (props) =>
    getPathnameWithQueryString(`/main/groups/${props?.keys?.[0]}/sections/${props?.keys?.[1]}`, props?.querystring),
  [PageKey.MAIN_HEADER_THUMBNAIL]: (props) =>
    getPathnameWithQueryString(`/main/groups/${props?.keys?.[0]}/thumbnails`, props?.querystring),
  [PageKey.MAIN_PAGE_SETTING]: (props) => getPathnameWithQueryString('/main/page-setting', props?.querystring),
  [PageKey.MAIN_MODULE_SETTING]: (props) => getPathnameWithQueryString('/main/module-setting', props?.querystring),
  [PageKey.SUB_PAGE_SETTING]: (props) => getPathnameWithQueryString('/sub-page', props?.querystring),
  [PageKey.DAGOO_AMULET]: (props) => getPathnameWithQueryString(`/dagoo/amulet`, props?.querystring),
  [PageKey.DAGOO_ACHIEVEMENTS]: (props) => getPathnameWithQueryString(`/dagoo/achievements`, props?.querystring),
  [PageKey.COMMUNITY_TOPIC]: (props) => getPathnameWithQueryString(`/community/topic`, props?.querystring),
  [PageKey.COMMUNITY_BANNER]: (props) => getPathnameWithQueryString(`/community/banner`, props?.querystring),
  [PageKey.QUIZ_TEMPLATES]: (props) => getPathnameWithQueryString(`/black-story/quiz-templates`, props?.querystring),
  [PageKey.TUTORIAL_QUIZ]: (props) => getPathnameWithQueryString(`/black-story/tutorial-quizzes`, props?.querystring),
  [PageKey.TUTORIAL_USER]: (props) => getPathnameWithQueryString(`/black-story/tutorial-users`, props?.querystring),
  [PageKey.ROOM]: (props) => getPathnameWithQueryString(`/black-story/rooms`, props?.querystring)
};
