export enum PageKey {
  DASHBOARD = 'DASHBOARD',
  GIFT_CARD_CONTENT_LIST = 'GIFT_CARD_CONTENT_LIST',
  GIFT_CARD_LIST = 'GIFT_CARD_LIST',
  GIFT_CARD_CREATE = 'GIFT_CARD_CREATE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  APP_MAIN_EDIT = 'APP_MAIN_EDIT',
  SPLASH_SCREEN = 'SPLASH_SCREEN',
  POP_UPS = 'POP_UPS',
  ON_BOARDING = 'ON_BOARDING',
  PUSH_SEND = 'PUSH_SEND',
  PUSH_RESERVATION = 'PUSH_RESERVATION',
  EVENT_PAGE = 'EVENT_PAGE',
  MANAGE_USER = 'MANAGE_USER',
  PLAY_LOG = 'PLAY_LOG',
  PLAY_LOGS = 'PLAY_LOGS',
  USER_STATISTICS = 'USER_STATISTICS',
  QUIT_USERS = 'QUIT_USERS',
  OWNED_GAME = 'OWNED_GAME',
  USAGE_LIST = 'USAGE_LIST',
  POINT_DASHBOARD = 'POINT_DASHBOARD',
  POINT_LOG = 'POINT_LOG',
  POINT_PROMOTION = 'POINT_PROMOTION',
  POINT_PROVISION = 'POINT_PROVISION',
  AD_PROJECT_LIST = 'AD_PROJECT_LIST',
  AD_LIMIT = 'AD_LIMIT',
  SYSTEM = 'SYSTEM',
  REPORT_CREATOR_FEED = 'REPORT_CREATOR_FEED',
  REPORT_CREATOR_COMMUNITY = 'REPORT_CREATOR_COMMUNITY',
  REPORT_USER_COMMUNITY = 'REPORT_USER_COMMUNITY',
  REPORT_REVIEW_AND_HELP = 'REPORT_REVIEW_AND_HELP',
  ETC_SHORT_LINK = 'ETC_SHORT_LINK',
  ETC_FILE = 'ETC_FILE',
  CREATOR_CHANNEL = 'CREATOR_CHANNEL',
  CREATOR_CHANNEL_PROJECT = 'CREATOR_CHANNEL_PROJECT',
  CONTENT_PROJECT = 'CONTENT_PROJECT',
  CONTENT_PROJECT_PARTICIPANT = 'CONTENT_PROJECT_PARTICIPANT',
  CONTENT_PROJECT_SCENARIO = 'CONTENT_PROJECT_SCENARIO',
  CONTENT_PROJECT_MISSION = 'CONTENT_PROJECT_MISSION',
  CONTENT_CHANNEL_DETAIL = 'CONTENT_CHANNEL_DETAIL',
  CONTENT_PREFERENCE = 'CONTENT_PREFERENCE',
  MAIN_GROUP = 'MAIN_GROUP',
  MAIN_SECTION = 'MAIN_SECTION',
  MAIN_SECTION_DETAIL = 'MAIN_SECTION_DETAIL',
  MAIN_HEADER_THUMBNAIL = 'MAIN_HEADER_THUMBNAIL',
  MAIN_PAGE_SETTING = 'MAIN_PAGE_SETTING',
  MAIN_MODULE_SETTING = 'MAIN_MODULE_SETTING',
  SUB_PAGE_SETTING = 'SUB_PAGE_SETTING',
  DAGOO_AMULET = 'DAGOO_AMULET',
  DAGOO_ACHIEVEMENTS = 'DAGOO_ACHIEVEMENTS',
  COMMUNITY_TOPIC = 'COMMUNITY_TOPIC',
  COMMUNITY_BANNER = 'COMMUNITY_BANNER',
  QUIZ_TEMPLATES = 'QUIZ_TEMPLATES',
  TUTORIAL_QUIZ = 'TUTORIAL_QUIZ',
  TUTORIAL_USER = 'TUTORIAL_USER',
  ROOM = 'ROOM'
}
