import { useAsyncFn } from 'react-use';
import { useParams } from 'react-router-dom';
import { ProjectFilterType, ProjectGenreFilterType } from '@uniquegood/realworld-admin-interface';
import { useEffect } from 'react';
import { applicationProjectApi, projectApi } from '@src/apis/admin';

interface UseProjectListParams {
  currentPage: number;
  searchKeyword: string;
  filter: ProjectFilterType[];
  genreFilter: ProjectGenreFilterType[];
  isCreatorProject: boolean;
}

export default function useProjectList({
  currentPage,
  searchKeyword,
  filter,
  genreFilter,
  isCreatorProject
}: UseProjectListParams) {
  const { id } = useParams();

  const [projectList, fetchProjectList] = useAsyncFn(async () => {
    try {
      if (isCreatorProject) {
        const { data } = await applicationProjectApi.getApplicationProjects(id || '', searchKeyword, currentPage, 15);

        return data.data;
      }
      const { data } = await projectApi.getProjects(searchKeyword, filter, genreFilter, currentPage, 15);

      return data.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [currentPage, searchKeyword, filter, genreFilter]);

  useEffect(() => {
    fetchProjectList();
  }, [fetchProjectList]);

  return [projectList, fetchProjectList] as const;
}
