import { Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import { TutorialQuiz } from '@src/models/quiz';
import Button from '@src/components/Button';

interface TutorialQuizColumnProps {
  openCorrectStoryModal: (story: string) => void;
  openBackgroundImageModal: (url: string) => void;
  onEdit?: (room: TutorialQuiz) => void;
  onDelete?: (room: TutorialQuiz) => void;
}

const TUTORIAL_QUIZ_TYPE = [
  { key: 'Common', value: '일반', color: '' },
  { key: 'Tutorial', value: '튜토리얼', color: '' }
];

export const TutorialQuizColumns = ({
  openCorrectStoryModal,
  openBackgroundImageModal,
  onDelete,
  onEdit
}: TutorialQuizColumnProps): ColumnsType<TutorialQuiz> => [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 80
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (type) => {
      return <Tag>{TUTORIAL_QUIZ_TYPE.find((item) => item.key === type)?.value}</Tag>;
    }
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 200
  },

  {
    title: '요약',
    dataIndex: 'summary',
    key: 'summary',
    width: 400
  },
  {
    title: '사건의 전말',
    dataIndex: 'correctStory',
    key: 'correctStory',
    width: 100,
    render: (correctStory) => {
      return (
        <Button icon={<SearchOutlined />} onClick={() => openCorrectStoryModal(correctStory)}>
          보기
        </Button>
      );
    }
  },
  {
    title: '배경 이미지',
    dataIndex: 'backgroundImageUrl',
    key: 'backgroundImageUrl',
    width: 100,
    render: (url) => {
      return (
        <Button icon={<EyeFilled />} onClick={() => openBackgroundImageModal(url)}>
          보기
        </Button>
      );
    }
  },
  {
    title: '액션',
    dataIndex: 'action',
    key: 'action',
    render: () => {
      return (
        <Space>
          <Button>수정</Button>
          <Button>삭제</Button>
        </Space>
      );
    }
  }
];
