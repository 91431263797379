import { PageHeader } from '@ant-design/pro-components';
import { Divider, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { PointEventFilterType, PointEventState, PointFilterType } from '@uniquegood/realworld-admin-interface/dist';
import DefaultLayout from '@src/components/DefaultLayout';
import { pointApi } from '@src/apis/admin';
import { YYYY_MM_DD } from '@src/constants/date';
import { LogFilter } from '../../../components/PointLogFilter';
import { PointLogDataTable } from '../../../components/DataTable';
import type { Dayjs } from 'dayjs';

export default function PointLog() {
  const [tabIndex, setTabIndex] = React.useState<PointFilterType>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filter, setFilter] = React.useState<PointEventFilterType[]>([]);
  const [state, setState] = React.useState<PointEventState>();
  const [username, setUsername] = React.useState('');
  const [date, setDate] = React.useState<[Dayjs | null, Dayjs | null]>([null, null]);

  const [pointLogApi, fetchPointLogApi] = useAsyncFn(async () => {
    try {
      const { data } = await pointApi.getAllPoints(
        tabIndex,
        filter,
        username,
        date[0]?.format(YYYY_MM_DD) || undefined,
        date[1]?.format(YYYY_MM_DD) || undefined,
        state,
        currentPage,
        20
      );

      return {
        ...data.data
      };
    } catch (e) {
      console.error(e);
    }
    return null;
  }, [currentPage, filter, tabIndex, username, date, state]);

  const items: TabsProps['items'] = [
    {
      key: 'All',
      label: '전체'
    },
    {
      key: 'Paid',
      label: '유료'
    },
    {
      key: 'Free',
      label: '무료'
    }
  ];

  React.useEffect(() => {
    fetchPointLogApi();
  }, [fetchPointLogApi]);

  return (
    <DefaultLayout>
      <PageHeader title="츄로 로그" subTitle="전체 츄로를 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Tabs
          items={items}
          onChange={(e) => {
            setTabIndex(e as PointFilterType);
            setCurrentPage(1);
            setFilter([]);
            setUsername('');
            setDate([null, null]);
            setState(undefined);
          }}
          destroyInactiveTabPane
        />
        <LogFilter refetch={{ setFilter, setUsername, setDate, setState }} pointFilterType={tabIndex} />
        <PointLogDataTable
          data={pointLogApi.value || {}}
          isLoading={pointLogApi.loading}
          refetch={{ setCurrentPage, setFilter, setUsername }}
          refetchFn={fetchPointLogApi}
        />
      </Content>
    </DefaultLayout>
  );
}
