import { PageHeader } from '@ant-design/pro-components';
import { Divider, Input, Pagination, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetTutorialUsers } from '@src/apis/black-story/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { GetTutorialUserParams, TutorialUser } from '@src/models/quiz';

import { TutorialUserColumns } from './TutorialUserColumns';

const { Search } = Input;

export default function () {
  const navigate = useNavigate();
  const queryData = new URLSearchParams(window.location.search);

  const { openModal: openDetailModal, closeModal: closeDetailModal, modal: detailModal } = useModalState();
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openModifyModal, closeModal: closeModifyModal, modal: modifyModal } = useModalState();

  const [totalCount, setTotalCount] = React.useState<number | undefined>(0);
  const [tutorialUsers, setTutorialUsers] = React.useState<TutorialUser[]>([]);

  const [query, setQuery] = useState<GetTutorialUserParams>({ limit: 10, page: 1 });
  const { data: tutorialUsersData, isLoading } = useGetTutorialUsers(query);

  useEffect(() => {
    console.log(tutorialUsersData);

    if (tutorialUsersData && !isLoading) {
      setTotalCount(tutorialUsersData.data.meta?.pagination?.totalCount);
      setTutorialUsers(tutorialUsersData.data.data ?? []);
    }
  }, [isLoading, tutorialUsersData]);

  return (
    <DefaultLayout>
      <PageHeader
        title="튜토리얼 유저 관리"
        subTitle="튜토리얼 유저를 관리합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Table
          tableLayout="auto"
          columns={TutorialUserColumns}
          dataSource={tutorialUsers}
          pagination={{
            position: ['bottomRight'],
            pageSize: query.limit,
            showSizeChanger: false,
            total: totalCount ?? 0,
            current: query.page ?? 1,
            onChange: (page) => setQuery({ ...query, page })
          }}
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        >
          <Pagination />
        </Table>
      </Content>
      {/* <TutorialUserCreateModal
        modal={createModal}
        closeCreateModal={closeCreateModal}
        refetchFn={() => {
          setQuery({ ...query });
        }}
      /> */}
    </DefaultLayout>
  );
}
