import styled from '@emotion/styled';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { useGetSubPages } from '@src/apis/admin/queries';
import DefaultLayout from '@src/components/DefaultLayout';
import Tabs from './Tabs';
import TabContent from './TabContent';

export default function SubPageSetting() {
  const { data: subPages } = useGetSubPages();

  return (
    <DefaultLayout>
      <PageHeader title="서브 페이지 관리" subTitle="서브 페이지를 관리합니다" style={{ margin: '16px 20px 0 20px' }} />
      <Content style={{ padding: '0 24px', margin: '0px 20px 36px' }}>
        <Container>
          <Tabs
            data={
              subPages?.data.map((subPage) => ({
                key: subPage.id,
                label: subPage.name,
                children: <TabContent id={subPage.id} />
              })) || []
            }
          />
        </Container>
      </Content>
    </DefaultLayout>
  );
}

const Container = styled.div`
  flex: 1;
`;
