import { PromotionRewardResponseModel } from '@uniquegood/realworld-admin-interface/dist';
import { Input, InputNumber, Modal, ModalProps, Select, Space } from 'antd';
import React, { BaseSyntheticEvent } from 'react';
import { uuidv4 } from '@src/utils/uuid';

/**
 * schema
 * title: string
 * description: string
 * joinEnableCount: number
 * startedAt: Date
 * endedAt: Date
 * promotionType: Daily | Basic
 * manager: string
 * viewType: ?
 * promotionRewards: [
 *   {
 *     rewardPoint: number
 *     description: string
 *     isActivate: boolean
 *     message: string
 *   }
 * ]
 * isActivate: boolean
 */

interface Item {
  rewardPoint?: number;
  rewardDescription?: string;
  isActivate?: boolean;
  message?: string;
}

interface PromotionRewardCreateModalProps {
  closeModal: () => unknown;
  modal: ModalProps;
  list: (PromotionRewardResponseModel & { key: React.Key; id: string; isNew?: boolean })[];
  setList: React.Dispatch<
    React.SetStateAction<(PromotionRewardResponseModel & { key: React.Key; id: string; isNew?: boolean })[]>
  >;
}

export function PromotionRewardCreateModal({ closeModal, modal, list, setList }: PromotionRewardCreateModalProps) {
  const [input, setInput] = React.useState<Item>();

  const handlePointChange = (value: 0 | null) => {
    setInput({
      ...input,
      rewardPoint: value as number
    });
  };

  const handleIsActivateChange = (value: boolean) => {
    setInput({
      ...input,
      isActivate: value
    });
  };

  const handleDescriptionChange = (e: BaseSyntheticEvent) => {
    setInput({
      ...input,
      rewardDescription: e.target.value
    });
  };

  const handleMessageChange = (e: BaseSyntheticEvent) => {
    setInput({
      ...input,
      message: e.target.value
    });
  };

  const handleClick = () => {
    setInput(undefined);
    setList([
      ...list,
      {
        ...input,
        key: uuidv4(),
        isNew: true
      } as PromotionRewardResponseModel & { key: React.Key; isNew?: boolean; id: string }
    ]);
    closeModal();
  };

  return (
    <Modal {...modal} onOk={handleClick} okText="저장" cancelText="닫기">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space>
          <InputNumber onChange={handlePointChange} id="rewardPoint" min={0} placeholder="츄로 수량" width="100%" />
          <Select
            onChange={handleIsActivateChange}
            id="isActivate"
            options={[
              {
                value: true,
                label: '활성화'
              },
              {
                value: false,
                label: '비활성화'
              }
            ]}
            placeholder="활성화 여부"
          />
        </Space>
        <Input.TextArea onChange={handleDescriptionChange} id="rewardDescription" autoSize placeholder="보상 설명" />

        <Input onChange={handleMessageChange} id="message" placeholder="보상 획득 메시지" />
      </Space>
    </Modal>
  );
}
