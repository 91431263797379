import { LoadingOutlined } from '@ant-design/icons';
import { Image, Modal, ModalProps, Spin, Space } from 'antd';
import Button from '@src/components/Button';

interface BackgroundImageModalProps {
  modal: ModalProps;
  backgroundImage: string;
  closeModal: () => void;
}

export function BackgroundImageModal({ modal, closeModal, backgroundImage }: BackgroundImageModalProps) {
  const Spinner = (
    <Space style={{ justifyContent: 'center', alignItems: 'center', width: '480px', height: '600px' }}>
      <Spin indicator={<LoadingOutlined />} size="large" />
    </Space>
  );
  return (
    <Modal
      title="배경 이미지"
      onCancel={closeModal}
      open={modal.open}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          닫기
        </Button>
      ]}
    >
      <Image
        key={backgroundImage}
        src={backgroundImage}
        placeholder={Spinner}
        preview={false}
        style={{ minHeight: 800, width: '100%', alignItems: 'center', justifyContent: 'center' }}
      />
    </Modal>
  );
}
