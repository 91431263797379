import axios from 'axios';
import {
  CreateQuizTemplate,
  GetQuizTemplateParams,
  GetRoomParams,
  GetTutorialQuizParams,
  GetTutorialUserParams,
  QuizTemplate,
  Room,
  TutorialQuiz,
  TutorialUser,
  UpdateQuizTemplate
} from '@src/models/quiz';
import { BLACK_STORY_HOST } from '@src/config';

export interface ApiResponse<T> {
  data: T;
  message?: string;
  meta?: {
    pagination?: {
      totalCount: number;
      currentPage: number;
      totalPages: number;
      hasMore: boolean;
    };
  };
}

export type PaginatedResponse<T> = ApiResponse<T[]>;

const api = axios.create({
  baseURL: `https://${BLACK_STORY_HOST}`,
  headers: { 'Content-Type': 'application/json' }
});

export const getQuizTemplates = async (query: GetQuizTemplateParams) => {
  return api.get<PaginatedResponse<QuizTemplate>>('/quiz-templates', { params: query });
};

export const getQuizzesByTemplateId = async (templateId: string) => {
  return api.get(`/quizzes?templateId=${templateId}`);
};

export const createQuizTemplate = async (template: CreateQuizTemplate) => {
  return api.post('/quiz-templates', template);
};

export const createBulkQuizTemplate = async (templates: CreateQuizTemplate[]) => {
  return api.post('/quiz-templates/bulk', templates);
};

export const updateQuizTemplate = async (id: number, template: UpdateQuizTemplate) => {
  return api.put(`/quiz-templates/${id}`, template);
};

export const deleteQuizTemplate = async (id: number) => {
  return api.delete(`/quiz-templates/${id}`);
};

export const getTutorialQuizzes = async (query: GetTutorialQuizParams) => {
  return api.get<PaginatedResponse<TutorialQuiz>>('/quizzes?isTutorial=true', { params: query });
};

export const getTutorialUsers = async (query: GetTutorialUserParams) => {
  return api.get<PaginatedResponse<TutorialUser>>('/users', { params: query });
};

export const getRooms = async (query: GetRoomParams) => {
  return api.get<PaginatedResponse<Room>>('/rooms', { params: query });
};
