import { useSortable } from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { CSS } from '@dnd-kit/utilities';
import { CloseOutlined } from '@ant-design/icons';
import { ClueCategory, SubPageDetailResponseModel } from '@uniquegood/realworld-admin-interface';
import useModalState from '@src/hooks/useModalState';
import ModifyModal from './ModifyModal';

interface ProjectItemProps {
  id: string;
  data?: SubPageDetailResponseModel;
  onDeleteClick?: (id: string) => unknown;
  prevData: SubPageDetailResponseModel[];
  category: ClueCategory | undefined;
}

export default function ProjectItem({ id, data, onDeleteClick, prevData, category }: ProjectItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  });
  const { openModal, closeModal, modal } = useModalState();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1
  };

  const handleClick = () => {
    openModal({
      title: '프로젝트 수정'
    });
  };

  return (
    <>
      <Container ref={setNodeRef} {...attributes} {...listeners} style={style} onClick={handleClick}>
        <InfoContainer>
          <Title>{data?.name}</Title>
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation();

              onDeleteClick?.(id);
            }}
          />
        </InfoContainer>

        {data?.imageUrl && <img draggable={false} src={data.imageUrl} alt={`${data?.name} 이미지`} />}
      </Container>
      <ModifyModal
        modal={modal}
        onClose={closeModal}
        prevData={prevData}
        category={category}
        initialData={prevData.find((item) => item.projectId === id)}
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 300px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  & > img {
    flex-grow: 1;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
`;

const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
  word-break: keep-all;
`;
