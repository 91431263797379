import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { ClueCategory, SubPageDetailResponseModel } from '@uniquegood/realworld-admin-interface';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useGetSubPageDetail } from '@src/apis/admin/queries';
import { clueCategoryToLabel } from '@src/constants/clue-category';
import { usePatchSubPage } from '@src/apis/admin/mutations';
import ProjectItem from './ProjectItem';
import CreateModal from './CreateModal';

interface TabContentProps {
  id: string;
}

export default function TabContent({ id }: TabContentProps) {
  const [searchParams] = useSearchParams();
  const spaceId = searchParams.get('spaceId') || '';

  const { data, isLoading } = useGetSubPageDetail(id);
  const { mutateAsync: patchSubPage } = usePatchSubPage();

  const categories = useMemo(
    () => [...new Set(data?.data.map((item) => item.clueCategory))].filter((item) => item),
    [data?.data]
  );

  const [localData, setLocalData] = useState<SubPageDetailResponseModel[]>();
  const [selectedCategory, setSelectedCategory] = useState<ClueCategory>();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setLocalData((items) => {
        try {
          const itemIndexes = items?.map((item) => item.projectId);
          const oldIndex = itemIndexes?.indexOf(String(active.id));
          const newIndex = itemIndexes?.indexOf(String(over?.id));

          const nextState = arrayMove(items || [], oldIndex || 0, newIndex || 0);

          const fetch = async () => {
            try {
              await patchSubPage({
                id: spaceId,
                category: selectedCategory,
                body:
                  nextState.map((item, index) => ({
                    ...item,
                    order: index + 1
                  })) || []
              });

              toast.success('프로젝트 순서를 변경했습니다.');
            } catch {
              toast.error('프로젝트 순서 변경에 실패했습니다.');
            }
          };

          fetch();

          return nextState;
        } catch {
          toast.error('프로젝트 순서 변경에 실패했습니다.');
          return items;
        }
      });
    }
  };

  const handleDeleteClick = async (id: string) => {
    try {
      setLocalData(localData?.filter((item) => item.projectId !== id));
      await patchSubPage({
        id: spaceId,
        category: selectedCategory,
        body: localData?.filter((item) => item.projectId !== id) || []
      });
      toast.success('프로젝트를 삭제했습니다.');
    } catch {
      toast.error('프로젝트 삭제에 실패했습니다.');
    }
  };

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }

    setLocalData(data?.data.filter((item) => (selectedCategory ? item.clueCategory === selectedCategory : true)));
  }, [categories, data?.data, selectedCategory]);

  if (isLoading) {
    return <> </>;
  }

  return (
    <div>
      <ControllerContainer>
        {selectedCategory && (
          <Select
            value={selectedCategory}
            options={categories.map(
              (category) =>
                (category && {
                  label: clueCategoryToLabel[category],
                  value: category
                }) ||
                {}
            )}
            onChange={setSelectedCategory}
          />
        )}
        <CreateModal.Button category={selectedCategory} prevData={localData || []} />
      </ControllerContainer>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter} sensors={sensors}>
        <SortableContext items={localData?.map((item) => item.projectId) || []} strategy={rectSortingStrategy}>
          <GridContainer>
            {localData?.map((item) => (
              <ProjectItem
                id={item.projectId}
                key={item.projectId}
                data={item}
                onDeleteClick={handleDeleteClick}
                prevData={localData || []}
                category={selectedCategory}
              />
            ))}
          </GridContainer>
        </SortableContext>
      </DndContext>
    </div>
  );
}

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  gap: 8px;
`;

const ControllerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 24px;
`;
