import { Button, Input, Modal, ModalProps, Select } from 'antd';
import { ClueCategory, SubPageDetailResponseModel } from '@uniquegood/realworld-admin-interface';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetProjects } from '@src/apis/admin/queries';
import useModalState from '@src/hooks/useModalState';
import { usePatchSubPage } from '@src/apis/admin/mutations';

interface CreateModalProps {
  modal: ModalProps;
  onClose: () => unknown;
  category?: ClueCategory;
  prevData: SubPageDetailResponseModel[];
}

export default function CreateModal({ modal, onClose, category, prevData }: CreateModalProps) {
  const { data: projects, isLoading } = useGetProjects({
    filter: ['Preparing', 'Released']
  });
  const { mutateAsync: patchSubPage } = usePatchSubPage();
  const [searchParams] = useSearchParams();
  const spaceId = searchParams.get('spaceId') || '';

  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [reservationLinkUrl, setReservationLinkUrl] = useState('');

  const handleSubmit = async () => {
    try {
      // 이미 목록에 존재하는 프로젝트를 추가하려는 경우
      if (prevData.map((item) => item.projectId).includes(selectedProjectId)) {
        toast.error('이미 추가된 프로젝트입니다.');
        return;
      }

      await patchSubPage({
        id: spaceId,
        category,
        body: [...prevData, { projectId: selectedProjectId, order: prevData.length + 1, reservationLinkUrl }]
      });

      toast.success('프로젝트가 추가되었습니다.');
      onClose();
    } catch {
      toast.error('프로젝트 추가에 실패했습니다.');
    }
  };

  return (
    <Modal {...modal} onOk={handleSubmit} onCancel={onClose} title="프로젝트 추가" okText="확인" cancelText="닫기">
      <Select
        placeholder="추가할 프로젝트를 선택해주세요."
        options={projects?.map((project) => ({
          label: project.name,
          value: project.id
        }))}
        showSearch
        onChange={setSelectedProjectId}
        disabled={isLoading}
        filterOption={(input, option) => {
          if (!option) return false;

          return option.label?.includes(input) || false;
        }}
      />
      <Input
        value={reservationLinkUrl}
        onChange={(e) => setReservationLinkUrl(e.target.value)}
        placeholder="예약 링크를 입력해주세요."
        style={{ marginTop: '8px' }}
      />
    </Modal>
  );
}

interface CreateModalButtonProps {
  category?: ClueCategory;
  prevData: SubPageDetailResponseModel[];
}

CreateModal.Button = function CreateModalButton({ category, prevData }: CreateModalButtonProps) {
  const { openModal, closeModal, modal } = useModalState();

  const handleClick = () => {
    openModal({});
  };

  return (
    <>
      <Button type="primary" onClick={handleClick}>
        추가하기
      </Button>
      <CreateModal modal={modal} onClose={closeModal} category={category} prevData={prevData} />
    </>
  );
};
