import {
  AuthApi,
  CommunityReportApi,
  Configuration,
  FileApi,
  MainPageApi,
  NoticeApi,
  PushApi,
  PointApi,
  PromotionApi,
  UserApi,
  ProductApi,
  AdvertisementApi,
  MiscApi,
  ApplicationApi,
  ProjectApi,
  RedeemApi,
  ScenariosApi,
  AmuletApi,
  ModuleApi,
  CurationApi,
  CommunityTopicApi,
  CommunityBannerApi,
  ApplicationProjectApi,
  HeaderthumbnailApi,
  MainPageGroupApi,
  MainPageSectionApi,
  ProjectCommentApi,
  SubPageApi
} from '@uniquegood/realworld-admin-interface';
import { ADMIN_SERVER_HOST } from '@src/config';
import { getDefaultAxiosInstance } from '..';

const configuration = new Configuration({ basePath: `https://${ADMIN_SERVER_HOST}` });

const defaultAxiosInstance = getDefaultAxiosInstance();

export const authApi = new AuthApi(configuration, undefined, defaultAxiosInstance);
export const mainPageApi = new MainPageApi(configuration, undefined, defaultAxiosInstance);
export const fileApi = new FileApi(configuration, undefined, defaultAxiosInstance);
export const noticeApi = new NoticeApi(configuration, undefined, defaultAxiosInstance);
export const pushApi = new PushApi(configuration, undefined, defaultAxiosInstance);
export const communityReportApi = new CommunityReportApi(configuration, undefined, defaultAxiosInstance);
export const pointApi = new PointApi(configuration, undefined, defaultAxiosInstance);
export const promotionApi = new PromotionApi(configuration, undefined, defaultAxiosInstance);
export const userApi = new UserApi(configuration, undefined, defaultAxiosInstance);
export const productApi = new ProductApi(configuration, undefined, defaultAxiosInstance);
export const advertisementApi = new AdvertisementApi(configuration, undefined, defaultAxiosInstance);
export const miscApi = new MiscApi(configuration, undefined, defaultAxiosInstance);
export const applicationApi = new ApplicationApi(configuration, undefined, defaultAxiosInstance);
export const applicationProjectApi = new ApplicationProjectApi(configuration, undefined, defaultAxiosInstance);
export const projectApi = new ProjectApi(configuration, undefined, defaultAxiosInstance);
export const redeemApi = new RedeemApi(configuration, undefined, defaultAxiosInstance);
export const scenarioApi = new ScenariosApi(configuration, undefined, defaultAxiosInstance);
export const amuletApi = new AmuletApi(configuration, undefined, defaultAxiosInstance);
export const moduleApi = new ModuleApi(configuration, undefined, defaultAxiosInstance);
export const curationApi = new CurationApi(configuration, undefined, defaultAxiosInstance);
export const communityTopicApi = new CommunityTopicApi(configuration, undefined, defaultAxiosInstance);
export const communityBannerApi = new CommunityBannerApi(configuration, undefined, defaultAxiosInstance);
export const subPageApi = new SubPageApi(configuration, undefined, defaultAxiosInstance);

// 홈 개편 관련 API
export const headerThumbnailApi = new HeaderthumbnailApi(configuration, undefined, defaultAxiosInstance);
export const mainPageGroupApi = new MainPageGroupApi(configuration, undefined, defaultAxiosInstance);
export const mainPageSectionApi = new MainPageSectionApi(configuration, undefined, defaultAxiosInstance);
export const projectCommentApi = new ProjectCommentApi(configuration, undefined, defaultAxiosInstance);
