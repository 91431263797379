import dayjs from 'dayjs';
import { Avatar, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TutorialUser } from '@src/models/quiz';

// dayjs 설정

export const TutorialUserColumns: ColumnsType<TutorialUser> = [
  {
    title: 'No.',
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    width: 80
  },
  {
    title: '참여자',
    dataIndex: 'nickname',
    key: 'nickname',
    width: 150,
    render: (nickname, user) => {
      return (
        <Space>
          <Avatar src={user.profileImageUrl} />
          <Tag color="black">{nickname}</Tag>
        </Space>
      );
    }
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 200,
    render: (createdAt: string) => {
      return <Tag>{dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Tag>;
    }
  }
];
