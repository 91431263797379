import { Tabs as AntdTabs } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface TabsProps {
  data: { key: string; label: string; children?: ReactNode }[];
}

export default function Tabs({ data }: TabsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const spaceId = searchParams.get('spaceId') || '';

  const [currentTab, setCurrentTab] = useState(spaceId);

  const handleChange = (value: string) => {
    setSearchParams({ spaceId: value });
  };

  useEffect(() => {
    if (data.length === 0) return;

    if (spaceId === '') {
      setSearchParams({ spaceId: data[0]?.key });
      setCurrentTab(data[0]?.key);
    } else {
      setSearchParams({ spaceId });
      setCurrentTab(spaceId);
    }
  }, [data, setSearchParams, spaceId]);

  return <AntdTabs activeKey={currentTab} onChange={handleChange} size="middle" items={data} />;
}
