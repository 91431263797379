import {
  ActionSchemaType,
  AdvertisementType,
  PointAction,
  PointEventState,
  PromotionType,
  RewardType
} from '@uniquegood/realworld-admin-interface/dist';

export const PointActionLabel: Record<PointAction, string> = {
  [PointAction.Earned]: '적립',
  [PointAction.Purchased]: '결제',
  [PointAction.Used]: '사용',
  [PointAction.Refunded]: '환불',
  [PointAction.Canceled]: '취소',
  [PointAction.Expired]: '만료',
  [PointAction.Retrieved]: '회수',
  [PointAction.Paid]: '지급',
  [PointAction.Etc]: '기타'
};

export const PointActionPaidList = [
  PointAction.Purchased,
  PointAction.Used,
  PointAction.Expired,
  PointAction.Refunded,
  PointAction.Canceled,
  PointAction.Etc
];
export const PointActionFreeList = [
  PointAction.Earned,
  PointAction.Used,
  PointAction.Canceled,
  PointAction.Paid,
  PointAction.Expired,
  PointAction.Retrieved,
  PointAction.Etc
];

export const AdvertisementTypeLabel: Record<AdvertisementType, string> = {
  [AdvertisementType.OfferWall]: '오퍼월',
  [AdvertisementType.RewardedVideo]: '리워드 영상'
};

export const RewardTypeLabel = {
  [RewardType.Hint]: '힌트',
  [RewardType.Point]: '츄로',
  [RewardType.Project]: '프로젝트',
  [RewardType.BlackstoryCorrectStoryViewTicket]: '바거스 정답 조회'
};

export const StateLabel = {
  Complete: '성공',
  Fail: '실패',
  Created: '생성됨'
};

export const PromotionTypeLabel: Record<PromotionType, string> = {
  [PromotionType.Basic]: '기본형',
  [PromotionType.Daily]: '데일리형',
  [PromotionType.Multi]: '멀티형'
};

export const SchemaTypeLabel: Record<ActionSchemaType, string> = {
  [ActionSchemaType.Application]: '채널',
  [ActionSchemaType.CommunityPost]: '커뮤니티 글',
  [ActionSchemaType.CommunityPostComment]: '커뮤니티 댓글,답글',
  [ActionSchemaType.Help]: '도움글',
  [ActionSchemaType.Post]: '크리에이터 커뮤니티 글',
  [ActionSchemaType.PostComment]: '크리에이터 커뮤니티 댓글',
  [ActionSchemaType.Project]: '프로젝트(게임)',
  [ActionSchemaType.Review]: '후기글',
  [ActionSchemaType.AccountInfo]: '추가 정보 입력',
  [ActionSchemaType.MarketingAgree]: '마케팅 수신 동의',
  [ActionSchemaType.BlackstoryLevelUp]: '바다거북스프 레벨업'
};

export const PointEventStateLabel: Record<PointEventState, string> = {
  [PointEventState.Created]: '생성',
  [PointEventState.Success]: '성공',
  [PointEventState.Fail]: '실패'
};
