import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClueCategory, SubPageRequestModel } from '@uniquegood/realworld-admin-interface';
import { subPageApi } from '.';

export const usePatchSubPage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['patchSubPage'],
    mutationFn: async ({
      id,
      category,
      body
    }: {
      id: string;
      category?: ClueCategory;
      body: SubPageRequestModel[];
    }) => {
      const { data } = await subPageApi.updateSubPageDetail(id, category, body);

      return data;
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: ['getSubPageDetail', id] });
    }
  });
};
